import LakshmiRavindran from "../Assets/Mrs-LakshmiRavindran.jpg";
import businesswomanicon from "../Assets/businesswomanicon.png";
import dummyImage from "../Assets/dummy-image-removebg-preview.png";
import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useSwipeable } from 'react-swipeable';

import {
  MDBCardText,
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBCardImage
} from 'mdb-react-ui-kit';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import "./css/App.css"
import { EffectCoverflow, Navigation } from 'swiper';

const About = () => {

  const imageContainerStyle = {
    padding: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px auto'
  };

  const imageStyle = {
    width: '150px',
    height: '150px',
    objectFit: 'cover',
    borderRadius: '50%'
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % mobile.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? mobile.length - 1 : prevIndex - 1
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });




  return (
    <>
      <style>
        {`
        .container {
          --primary: #6a59ff;
          --white: #ffffff;
          --bg: #f5f5f5;
        }
        .swiper-pagination {
          bottom: 100px;
          display: flex;
          justify-content: center;
        }
        .swiper-pagination .swiper-pagination-bullet {
          width: 8px; 
          height: 8px;
          background-color: rgba(0, 0, 0, 0.2); 
          margin: 0 4px; 
          border-radius: 50%; 
          opacity: 1;
          transition: opacity 0.15s ease-in-out; 
        }
        .swiper-pagination .swiper-pagination-bullet-active {
          background-color: rgba(0, 0, 0, 0.5);
        }
        @media (max-width: 576px) {
          .heading {
            font-size: 2rem;
            margin-top: 0;
            margin-bottom: 0;
          }
          .swiper-slide img {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
        /* Default styles for mobile screens */
.swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--white);
    transition: transform 0.3s;
}

/* Styles for tablet and larger screens */
@media (min-width: 768px) {
    .swiper-slide {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
}

        .About-swiper-slide:hover {
          transform: translateY(-10px);
        }
        .About-swiper-slide img {
          max-width: 150px;
          height: auto; 
          border-radius: 50%;
          margin-bottom: 10px;
        }
          @media (max-width: 576px) {
      .swiper-slide .text-justify {
    font-size: 14px; 
    line-height: 1.6; 
  }
}  `}
      </style>

      <section id="AboutUs" className=" mt-md-0 mt-lg-0 ">

        <Container fluid >
          <Row className='mt-md-5 d-flex justify-content-between '>
            <Col md={1} className=''>
              <Image src={require("../Assets/Flame-section2.png")}
                className='w-100 h-100 w-md-75 h-md-75 d-none d-md-none d-lg-block'
              ></Image>
            </Col>
            <Col md={4} className='d-md-none d-lg-block'>
              <div className='py-4 py-md-5 '>
                <h5 className='lead text-muted '>
                  ✦ About Us</h5>
                <p className='display-3 fw-semibold m-0'>Founder</p>
                <h1 className="">Mr. C. Ravindran</h1> <br />

                <div className="d-flex justify-content-center">
                  <MDBCardText className="text-justify fw-medium" style={{ textAlign: 'justify', textIndent: '1em' }}>
                    In the Tuticorin district's little town of Katcheri Thalavaipuram, he was born on April 11, 1944.
                    <p style={{ margin: '10px 0' }}></p>
                    He attended a government school in Kovilpatti for his last education before attending St. Xavier's College in Palayamkottai to earn his PUC.
                    <p style={{ margin: '10px 0' }}></p>
                    He graduated from Thiagarajar College in Madurai with a B.E. and Madura College with a B.Sc.
                    <p style={{ margin: '10px 0' }}></p>
                    He began working for the Highways Department in 1970 as a junior engineer and left as a divisional engineer in 2001.
                  </MDBCardText>
                </div>


              </div>
            </Col>
            {/*display md*/}
            <Col md={6} className='d-lg-none d-none d-md-block'>
              <div className='py-4 py-md-5 '>
                <h5 className='lead text-muted '>
                  ✦ About Us</h5>
                <p className='display-3 fw-semibold '>Founder</p>
                <h1>Mr. C. Ravindran</h1> <br /><br />

                <div className="d-flex justify-content-center">
                  <MDBCardText className="text-justify fw-medium" style={{ textAlign: 'justify', textIndent: '1em' }}>
                    In the Tuticorin district's little town of Katcheri Thalavaipuram, he was born on April 11, 1944. <br /><br />
                    He attended a government school in Kovilpatti for his last education before attending St. Xavier's College in Palayamkottai to earn his PUC.<br /><br />
                    He graduated from Theyagarajar College in Madurai with a B.E. and Madura College with a B.Sc.

                    He began working for the Highways Department in 1970 as a junior engineer and left as a divisional engineer in 2001.                </MDBCardText>
                </div>

              </div>
            </Col>


            {/*display lg*/}
            <Col md={6} className="d-none d-md-none d-lg-block d-flex justify-content-center align-items-center mt-4" fluid >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '500px', width: '500px', borderRadius: '50%', overflow: 'hidden', border: '20px solid yellow' }}
              >
                <div className="d-flex justify-content-center align-items-center h-100 w-100">
                  <Image
                    src={require("../Assets/Mr-C-Ravindran .jpg")}
                    fluid
                    alt="Mr-C-Ravindran"
                    className="h-auto w-75 object-fit-cover"
                  />
                </div>
              </div>
            </Col>


            {/*display md*/}
            <Col md={5} className="d-none d-md-block d-lg-none d-flex justify-content-center align-items-center mt-5" fluid >
              <div className="d-flex justify-content-center align-items-center"
                style={{ height: '300px', width: '300px', borderRadius: '50%', overflow: 'hidden', border: '20px solid yellow' }}               >
                <div className="h-100 w-100 d-flex justify-content-center align-items-center-5 mt-5">
                  <Image
                    src={require("../Assets/Mr-C-Ravindran .jpg")}
                    fluid
                    alt="Mr-C-Ravindran"
                    className="h-75 w-50 object-fit-cover"
                  />
                </div>
              </div>
            </Col>

            {/*display sm*/}
            <Col md={5} className=" d-md-none d-lg-none d-flex justify-content-center align-items-center" fluid >
              <div className="d-flex justify-content-center align-items-center"
                style={{ height: '300px', width: '300px', borderRadius: '50%', overflow: 'hidden', border: '20px solid yellow' }}
              >
                <div className="h-100 w-100 rounded-circle d-flex justify-content-center align-items-center ">
                  <Image
                    src={require("../Assets/Mr-C-Ravindran .jpg")}
                    fluid
                    alt="Mr-C-Ravindran"
                    className="h-75 w-50 object-fit-cover"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>


        {/*About Us-Partners*/}
        <div className="d-flex flex-wrap justify-content-center d-md-none">
          <Container fluid className="mt-4 ">
            <Row>
              <Col md={5}>
                <div>
                  <h5 className="lead text-muted">✦ About Us</h5>
                  <p className="display-3 fw-semibold">Partners</p>
                </div>
              </Col>
            </Row>
          </Container>

          <div {...handlers} className="card h-auto m-0" style={{ width: '18rem', margin: '1rem' }}>
            <div className="bg-primary d-flex justify-content-center align-items-center" style={imageContainerStyle}>
              <img
                src={mobile[currentIndex].img}
                className="card-img-top"
                alt="..."
                style={imageStyle}
              />
            </div>

            <div className=" card-body text-center">
              <p className="card-text fw-bold">
                {mobile[currentIndex].name}
              </p>
              <p className="card-text fw-semibold text-center " >
                {mobile[currentIndex].review}
              </p>

            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center" style={{ width: '18rem', margin: '1rem' }}>
            <button type="button" onClick={handlePrev} className="btn btn-primary rounded-circle">
              <IoIosArrowBack size={25} color="white" style={{ width: "20px", height: "30px" }} />
            </button>
            <button type="button" onClick={handleNext} className="btn btn-primary rounded-circle">
              <IoIosArrowForward size={25} color="white" style={{ width: "20px", height: "30px" }} />
            </button>
          </div>
        </div>




        <div className="container d-none d-md-block" >
          <Container fluid className="my-4 ">
            <Row className="ms-md-4 ms-lg-0 ps-md-0 ps-xl-5 ms-xxl-5 ps-xxl-5 ">
              <Col md={5} className="ms-lg-5 ps-lg-5 ps-xxl-0 ">
                <div className='ms-md-3 ps-md-3 '>
                  <h5 className='lead text-muted '>✦ About Us</h5>
                  <p className='display-3 fw-semibold'>Partners</p>
                </div>
              </Col>
            </Row>
          </Container>
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              clickable: true,
            }}
            modules={[EffectCoverflow, Navigation]}
            className="About-swiper_container"
          >
            {data.map((d, index) => (

              <SwiperSlide key={index} className="d-none d-md-block">
                <Container className="d-flex justify-content-center align-items-center ">
                  <Row className="g-3">
                    <Col className="d-flex align-items-stretch">
                      <MDBCard className='w-100 border-0'>
                        <div className='bg-primary '>
                          <div style={imageContainerStyle}>
                            <MDBCardImage
                              src={d.img}
                              alt={d.name}
                              style={imageStyle}
                            />
                          </div>
                        </div>
                        <MDBCardBody >
                          <MDBCardTitle className='h2 mb-4 '>{d.name}</MDBCardTitle>
                          <div className="text-justify">
                            <MDBCardText className="fw-medium" style={{ textAlign: 'justify', textIndent: '2em', fontSize: '14px' }}>
                              {d.review}
                            </MDBCardText>
                          </div>
                        </MDBCardBody>
                      </MDBCard>
                    </Col>
                  </Row>
                </Container>
              </SwiperSlide>
            ))}



            <div className="slider-controler d-none d-md-block" style={{ marginTop: "100px" }}>
              <div className="swiper-button-prev slider-arrow">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </div>
              <div className="swiper-button-next slider-arrow">
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </div>
            </div>

            <div className="slider-controler d-md-none" style={{ marginTop: "400px" }}>
              <div className="swiper-button-prev slider-arrow">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </div>
              <div className="swiper-button-next slider-arrow">
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </div>
            </div>

          </Swiper>
        </div>
      </section>

      <section className="bg-secondary py-md-5 position-relative">
        <Container>
          <Row className="position-relative">
            <Col>
              <div className="text-black ml-3">
                <h1 className="mb-4 fw-bold">Our Vision</h1>
                <p className="mb-4 h3 fw-medium">To be the first choice for the LPG customers in Madurai</p>
                <h1 className="mt-5 mb-4 fw-bold">Our Mission</h1>
                <ol className="h3 fw-medium mt-3 mb-5">
                  <li className="mb-3">Commitment to excel in service</li>
                  <li className="mb-3">Making customers happy</li>
                  <li className="mb-3">Teamwork</li>
                  <li className="mb-3">Profitable growth</li>
                </ol>
              </div>
            </Col>
          </Row>
        </Container>
        {/* Cylinder image */}
        <Col className="bottom-0 end-0 p-0">
          <Image
            src={require("../Assets/Cylinderflame.png")}
            fluid
            rounded
            alt="Cylinder with flame"
            className="position-absolute d-none d-lg-block"
            style={{ bottom: '0', right: '0', width: 'auto', maxHeight: '500px' }}
          ></Image>
        </Col>
      </section>
    </>
  );
};

const data = [
  {
    name: `Mrs. Lakshmi Ravindran`,
    img: LakshmiRavindran,
    review: `She was born on June 16, 1952, in the Dindigul district's "Pattiveeranpatti" village. She completed her education at a government high school for girls in Pattiveeranpatti and her B.A. in history at Fatima College in Madurai. At the time, she was a proud woman entrepreneur and the founder partner of Shri Balaji Agencies, the Madurai-based Bharatgas distributors that opened for business in 1982.`
  },

  {
    name: `Mrs. Rajee Manivannan`,
    img: businesswomanicon,
    review: `She has degrees in English, B.Ed, and M.Sc. in Psychology. She also serves as Honorary Principal at Ashvattha School in Madurai.`
  },
  {
    name: `Mr. R. Manivannan`,
    img: dummyImage,
    review: `He completed his M.E. at Thiagarajar College of Engineering in Madurai and his B.E. (Civil) at Mepco Schlenk Engineering College in Sivakasi. After spending 23 years working as an engineer in a variety of locations, including Madurai, Chennai, and Italy, the individual became a second-generation entrepreneur in Madurai.`
  },

  {
    name: `Mrs. Lakshmi Ravindran`,
    img: LakshmiRavindran,
    review: `She was born on June 16, 1952, in the Dindigul district's "Pattiveeranpatti" village. She completed her education at a government high school for girls in Pattiveeranpatti and her B.A. in history at Fatima College in Madurai. At the time, she was a proud woman entrepreneur and the founder partner of Shri Balaji Agencies, the Madurai-based Bharatgas distributors that opened for business in 1982.`
  },
  {
    name: `Mrs. Rajee Manivannan`,
    img: businesswomanicon,
    review: `She has degrees in English, B.Ed, and M.Sc. in Psychology. She also serves as Honorary Principal at Ashvattha School in Madurai.`
  },
  {
    name: `Mr. R. Manivannan`,
    img: dummyImage,
    review: `He completed his M.E. at Thiagarajar College of Engineering in Madurai and his B.E. (Civil) at Mepco Schlenk Engineering College in Sivakasi. After spending 23 years working as an engineer in a variety of locations, including Madurai, Chennai, and Italy, the individual became a second-generation entrepreneur in Madurai.`
  },

];

const mobile = [
  {
    name: `Mrs. Lakshmi Ravindran`,
    img: LakshmiRavindran,
    review: `She was born on June 16, 1952, in the Dindigul district's "Pattiveeranpatti" village. She completed her education at a government high school for girls in Pattiveeranpatti and her B.A. in history at Fatima College in Madurai. At the time, she was a proud woman entrepreneur and the founder partner of Shri Balaji Agencies, the Madurai-based Bharatgas distributors that opened for business in 1982.`
  },
  {
    name: `Mrs. Rajee Manivannan`,
    img: businesswomanicon,
    review: `She holds degrees in English, a B.Ed, and an M.Sc. in Psychology. She also serves as the Honorary Principal at Ashvattha School in Madurai.`
  },
  {
    name: `Mr. R. Manivannan`,
    img: dummyImage,
    review: `He completed his M.E. at Thiagarajar College of Engineering in Madurai and his B.E. (Civil) at Mepco Schlenk Engineering College in Sivakasi. After spending 23 years working as an engineer in a variety of locations, including Madurai, Chennai, and Italy, the individual became a second-generation entrepreneur in Madurai.`
  }
]

export default About;