import React, { useState, useEffect } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import ShriBalajiAgencies from "../Assets/ShriBalaji.jpg";

const Appbar = () => {
  const [expanded, setExpanded] = useState(false);
  const [showNavbarContent, setShowNavbarContent] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const aboutUsSection = document.getElementById("AboutUs");
      const homeSection = document.getElementById("Home");

      if (aboutUsSection && homeSection) {
        const aboutUsTop = aboutUsSection.getBoundingClientRect().top;
        const homeBottom = homeSection.getBoundingClientRect().bottom;

        if (aboutUsTop <= window.innerHeight * 0.10) {
          setShowNavbarContent(true);
        } else {
          setShowNavbarContent(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="light"
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      className={`ps-3 w-100 fixed-top pe-2   ${showNavbarContent ? "bg-primary" : ""}`}
    >
      <Container fluid className="d-flex justify-content-between align-items-center ">
        <div
          className={`bg-white rounded ms-0 ms-md-0 ms-lg-3  ${showNavbarContent ? "" : "d-none"}`}
          style={{ maxWidth: '350px', maxHeight: '100px' }}
        >
          <img
            src={ShriBalajiAgencies}
            alt="ShriBalajiAgencies"
            className="w-100 h-auto"
          />
        </div>

        {showNavbarContent && (
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="text-white " style={{ marginLeft: 'auto' }} />
        )}
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end pe-1 ">
          {showNavbarContent && (
            <Nav className="gap-xl-3 navbar-text-white me-3">
              <Navbar.Brand
                href="#Home"
                className="fw-bold fs-1 text-secondary d-lg-none"
              >
                Bharatgas
              </Navbar.Brand>
              <Nav.Link href="#Home" className="fw-semibold text-white fs-md-1">
                Home
              </Nav.Link>
              <Nav.Link href="#AboutUs" className="fw-semibold text-white fs-md-6">
                About Us
              </Nav.Link>
              <Nav.Link href="#Products" className="fw-semibold text-white text-md-sm">
                Products
              </Nav.Link>
              <Nav.Link href="#SafetyInstructions" className="fw-semibold text-white text-md-sm">
                Safety & Instructions
              </Nav.Link>
              <Nav.Link href="#ContactUs" className="fw-semibold text-white fs-6">
                Contact Us
              </Nav.Link>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Appbar;
