import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Gas1 from "../Assets/Gas/gas1.png";
import Gas2 from "../Assets/Gas/gas2.png";
// import Gas3 from "../Assets/Gas/gas3.png";
import Gas4 from "../Assets/Gas/gas4.png";
import Gas5 from "../Assets/Gas/gas5.png";
import Gas6 from "../Assets/Gas/gas6.png";
import Gas7 from "../Assets/Gas/gas7.png";

const Ourproducts = () => {
  return (
    <section id="Products" className="bg-light mt-3 mt-md-3">
      <Container>
        <div className="text-start">
          <h3 className="lead text-muted ps-3">✦ Our products</h3>
        </div>
        <div className="text-start">
          <h1 className="ps-3 ">
            Explore Our <br />
            Products
          </h1>
        </div>
        <Row>
          {cylinders.map((item, index) => (
            <Col key={index} md={6} lg={4}>
              <Card className="m-3 d-flex flex-row bg-light">
                <div className="">
                  <Card.Img
                    className="pt-3 px-2 pb-2 ps-md-4"
                    variant="top"
                    src={item.img}
                    style={{ width: "100px", height: "160px", objectFit: "contain" }}
                  />
                </div>
                <div className="mt-md-3">
                  <div className="d-flex justify-content-center align-items-center w-100 bg-primary">
                    <div className="card-body d-flex justify-content-center align-items-center bg-light">
                      <Col className="ps-md-5 pt-3 pt-md-0 mt-3 text-center ">
                        <p className="card-text bg-light h5">{item.name}</p>
                        <p className="card-text bg-light h6">{item.kg}</p>
                      </Col>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <Container fluid className="bg-primary text-center px-md-5">
        <Row className="align-items-center justify-content-center">
          <Col>
            <h2 className="display- pt-2 text-center text-sm-start ps-md-5 fw-bold">
              MINI GAS
            </h2>
            <h2 className="ps-md-5 display-5 text-center text-sm-start fw-bold text-secondary">
              MAXI COMFORT
            </h2>
            <h4 className="ps-md-5 text-center text-sm-start fw-bold text-light">
              Get Your Mini Bharatgas {"  "}
              <span className="d-block d-sm-inline h1 fw-bold text-secondary">
                5 Kg
              </span>
            </h4>
            <div className="d-flex justify-content-center justify-content-lg-start justify-content-md-start ps-md-5 w-md-75 ps-lg-5">
              <div className="bg-secondary rounded-pill p-2 my-3 w-50 ">
                <a
                  href="#products"
                  className="text-black text-decoration-none fs-5 fw-medium d-flex align-items-center justify-content-center"
                  onClick={() =>
                    document.getElementById("Footer").scrollIntoView({ behavior: "smooth" })
                  }
                >
                  Order Now
                </a>
              </div>
            </div>
          </Col>

          <Col md={7}>
            <Image
              src={require("../Assets/GasWithGirl.jpg")}
              fluid
              rounded
              alt="Mountains"
              className="p-2 w-75 h-auto"
            ></Image>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const cylinders = [
  {
    name: "FTl Sales",
    kg: "5kg",
    img: Gas1,
  },
  {
    name: "FTl Refill",
    kg: "5kg Domestic",
    img: Gas2,
  },
  {
    name: "Commercial LOT",
    kg: "47.5kg",
    img: Gas6,
  },
  {
    name: "Hippo",
    kg: "422kg",
    img: Gas7,
  },
  {
    name: "Commercial",
    kg: "19kg",
    img: Gas5,
  },
  {
    name: "BMCG",
    kg: "19kg",
    img: Gas4,
  },
];

export default Ourproducts;
