import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  Image,
  Button,
} from "react-bootstrap";
import ShriBalajiAgencies from "../Assets/shribalajiagencie.jpg";
import CylinderImage from "../Assets/Bharathgaslogo.jpg";
import { IoIosArrowUp } from "react-icons/io";


const Home = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  const largeScreenStyles = {
    width: window.innerWidth >= 992 ? '93%' : '100%',
    height: window.innerWidth >= 992 ? '82%' : 'auto',
  };

  return (
    <>
      <style type="text/css">
        {`
            .scroll-to-top-button {
                position: fixed;
                bottom: 1rem;
                right: 1rem;
                z-index: 10;
                transition: opacity 0.3s ease-in-out;
            }
            
            .scroll-to-top-button.opacity-0 {
                opacity: 0;
                pointer-events: none;
            }
            
            .scroll-to-top-button.opacity-100 {
                opacity: 1;
                pointer-events: all;
            }

            .nav-link {
                position: relative;
                color: white;
            }

            .nav-link::after {
                content: '';
                position: absolute;
                width: 0;
                height: 2px;
                display: block;
                margin-top: 5px;
                right: 0;
                background: yellow;
                transition: width 0.3s ease, background-color 0.3s ease;
            }

            .nav-link:hover {
                color: yellow !important;
            }

            .nav-link:hover::after {
                width: 100%;
                left: 0;
                background: white;
            }
            
           .underline-text {
                        position: relative;
                        display: inline-block;
                    }

                    .underline-text::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -3px; 
                        width: 100%;
                        height: 2px; 
                        background-color: currentColor; 
                    }

                    .my-4 {
                        margin-top: 1.5rem !important;
                        margin-bottom: 1.5rem !important;
                    }

                    .gap-5 {
                        gap: 3rem !important;
                    }

                    .border-start-5 {
                        border-left-width: 5px !important;
                    }
        `}
      </style>
      <section id="Home">
        <Container fluid >
          <Row>
            {/* Left Container for Logo */}
            <Col md={5} className="p-0">
              <img
                src={ShriBalajiAgencies}
                alt="ShriBalajiAgencies"
                className="w-100 h-md-100 d-lg-none"
              />
              <Col className="d-none d-lg-flex justify-content-center align-items-center">
                <div style={{ backgroundColor: 'white', padding: '8px', marginLeft: '10px' }}>
                  <img
                    src={ShriBalajiAgencies}
                    alt="ShriBalajiAgencies"
                    style={{ width: '400px', height: '100px', marginBottom: '0.5rem' }}
                  />
                </div>
              </Col>
              {/*display lg */}

              <div
                className="d-flex d-none d-lg-block justify-content-center align-items-center"
                style={{ largeScreenStyles, borderRadius: '50%', overflow: 'hidden', border: '20px solid yellow' }}
              >
                <Image
                  src={require("../Assets/Bharathgaslogo.jpg")}
                  fluid
                  roundedCircle
                  alt="Firefly Two Indian women"
                  className=" img-round d-none d-md-none d-lg-block "
                  style={{ largeScreenStyles, objectFit: "cover" }}
                />
              </div>

              {/*display md */}
              <Col
                md={6}
                className="d-none d-md-block d-lg-none d-flex justify-content-center align-items-center mt-4"
                fluid
              >
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height: '300px',
                    width: '300px',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    border: '20px solid yellow'
                  }}
                >
                  <Image
                    src={require("../Assets/Bharathgaslogo.jpg")}
                    fluid
                    alt="Firefly Two Indian women"
                    className="h-100 w-100"
                    style={{ objectFit: 'cover' }}
                  />
                </div>
              </Col>
            </Col>

            {/* Right Container */}
            <Col
              md={7}
              className="d-flex flex-column bg-primary rounded-lg h-auto "
              style={{ borderBottomRightRadius: "30%" }}
            >
              <Navbar
                collapseOnSelect
                expand="lg"
                bg="transparent"
                className="w-100 d-flex justify-content-end"
              >
                <Navbar.Toggle
                  aria-controls="responsive-navbar-nav"
                  className="text-white"
                />
                <Navbar.Collapse
                  id="responsive-navbar-nav"
                  className=" justify-content-end"
                >
                  <Nav className="gap-xl-3 ">
                    <Navbar.Brand
                      href="#Home"
                      className="fw-bold fs-1 text-secondary d-lg-none"
                    >
                      Bharatgas
                    </Navbar.Brand>
                    <Nav.Link
                      href="#Home"
                      className="fw-semibold fs-md-1 text-white"
                    >
                      Home
                    </Nav.Link>
                    <Nav.Link
                      href="#AboutUs"
                      className="fw-semibold fs-md-6 text-white"
                    >
                      About Us
                    </Nav.Link>
                    <Nav.Link
                      href="#Products"
                      className="fw-semibold text-md-sm text-white"
                    >
                      Products
                    </Nav.Link>
                    <Nav.Link
                      href="#SafetyInstructions"
                      className="fw-semibold text-md-sm text-white"
                    >
                      Safety & Instructions
                    </Nav.Link>
                    <Nav.Link
                      href="#ContactUs"
                      className="fw-semibold fs-6 text-white"
                    >
                      Contact Us
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div className="p-5 d-none d-md-none d-lg-block"></div>
              <div className="text-center mt-2 d-flex flex-column justify-content-end">
                <div className="kitchen-safety display-4 fw-bold text-white">
                  Kitchen <span className="text-secondary">Safety</span> <br />
                  <span className="text-secondary">Starts</span> With You
                </div>

                <div className="my-4 text-white d-flex gap-5 justify-content-center">
                  <p style={{ textAlign: 'left' }}>
                    <span className="underline-text">More Than 75,000+ </span>
                    <br /> Happy <br /> Customers
                  </p>

                  <p style={{ textAlign: 'left' }}>
                    <span className="underline-text">10+</span>
                    <br /> Valuable <br /> Products
                  </p>

                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="w-auto w-lg-75 bg-secondary rounded-pill px-2 py-1 p-md-2 mb-3 ">
                    <a
                      href="#products"
                      className="text-black text-decoration-none fs-5  fw-medium d-flex align-items-center justify-content-center"
                      onClick={() =>
                        document
                          .getElementById("Products")
                          .scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Explore Products
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {/* Parent container to center the circle */}
          <div className="d-flex d-md-none justify-content-center align-items-center mt-2">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                height: '250px',
                width: '250px',
                borderRadius: '50%',
                overflow: 'hidden',
                border: '20px solid yellow'
              }}
            >
              {/* Display this image on smaller screens */}
              <img
                src={CylinderImage}
                alt="Happy Customers"
                className="d-md-none"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            </div>
          </div>
        </Container>
      </section>
      <Button
        onClick={scrollToTop}
        className={`scroll-to-top-button btn btn-primary rounded-circle shadow-lg ${visible ? "opacity-100" : "opacity-0"
          }`}
      >
        <IoIosArrowUp
          size={25}
          color="white"
          style={{ width: "20px", height: "30px" }}
        />
      </Button>
    </>
  );
};

export default Home;
