import React from "react";

const Footer = () => {
  return (
    <div className="text-center p-0 bg-primary text-light small" id="Footer">
      <div className="p-3">
        Copyrights © 2024- Shri Balaji Gas Agencies Design & Developed by
        <a href="https://www.proglobalsolutions.in/" target="_blank" rel="noopener noreferrer" className="text-light text-decoration-none">
          {"  "}<b>Pro Global Solutions</b>
        </a>
      </div>
    </div>
  );
};
export default Footer;
